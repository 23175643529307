<template>
  <CRow>
    <CCol md="3" sm="6">
      <CWidgetBrand
        color="gradient-success"
        :right-header="$t(memberInfo.level)"
        :right-footer="$t('widget.MembershipLevel')"
        :left-header="memberInfo.expiredAt | moment('YYYY-MM-DD')"
        :left-footer="$t('widget.ValidPeriod')"
        class="thesmall"
        ref="memberInfo"
      >
        <CIcon name="cib-dashlane" height="52" class="my-4" />

        <CRow>
          <CCol md="12">
            <h2 ml="3">
              <template v-if="memberInfo.status === 'trialing'">
                <CButton color="primary"
                         @click="pay('paytrial')"
                         class="mb-1">
                  {{ $t('Pay') }}
                </CButton>
              </template>
              <template v-else>
                <template v-if="memberInfo.level===$t('widget.FreeMembership')">
                  <CButton color="warning" @click="$router.push({name: 'MemberPlans'})">
                    <span>{{ $t('widget.JoinMember') }}</span>
                  </CButton>
                  <!--<CButton color="primary" @click="clickModify()"
                           class="ml-1" v-if="memberInfo.cname !== '-'">
                    {{ $t('widget.Modify') }}
                  </CButton>-->
                </template>
                <template v-else>
                  <CButton color="warning" @click="pay('renew')"
                           class="mr-1 mb-1">
                    {{ $t('widget.Renew') }}
                  </CButton>
                  <!--<CButton color="primary" @click="clickModify()"
                           class="mb-1">
                    {{ $t('widget.Modify') }}
                  </CButton>-->
                </template>
              </template>
            </h2>
          </CCol>
          <CCol></CCol>
        </CRow>
        <CDropdown
          class="float-right"
          color="transparent p-0"
          placement="bottom-end"
          v-if="selectedSubscription">
          <template #toggler-content>
            <CIcon name="cil-library"/>
          </template>
          <!-- <CDropdownItem :to="{name: 'MemberPlans'}"
                         v-show="selectedSubscription.plan.price.quantity > 0">
            <CIcon name="cil-plus"
                   class="mr-1"/>
            {{ $t('widget.AddSubscription') }}
          </CDropdownItem> -->
          <CDropdownItem 
            v-for="(subscription, index) in subscriptions"
            :key="subscription.id"
            @click="selectSubscription(subscription)">
            <div v-if="subscription && subscription.plan"> {{ subscription.plan.name }} - {{ subscription.cname }} </div>
          </CDropdownItem>
        </CDropdown>

        <template v-slot:body>
          <div class="card-body text-center widget-subscription">
            <span id="icon-info"
                  @click="showModalSubscription=true">
              <CIcon name="cil-info"/>
            </span>
            <div class="row">
              <div class="col">
                <div class="text-value-lg">
                  {{ memberInfo.level }}
                </div>
                <div class="text-uppercase text-muted small">
                  {{ $t('widget.MembershipLevel') }}
                </div>
              </div>
              <div class="c-vr">
              </div>
              <div class="col"
                   :class="{Expired: isNotifyTenDaysExpire}"
                   v-if="isNotifyTenDaysExpire"
                   v-c-tooltip="{
                   active: true,
                   content: $t('widget.WarningExpired')
                   }">
                <div class="text-value-lg">
                  {{ memberInfo.expiredAt }}
                </div>
                <div class="text-uppercase text-muted small">
                  {{ $t('widget.ExpireAt') }}
                </div>
              </div>
              <div class="col"
                   v-else>
                <div class="text-value-lg">
                  {{ memberInfo.expiredAt }}
                </div>
                <div class="text-uppercase text-muted small">
                  {{ $t('widget.ExpireAt') }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </CWidgetBrand>
    </CCol>

    <CCol md="2" sm="6">
      <CWidgetBrand
        color="gradient-info"
        :right-header="domain_stat + ''|| '0'"
        :right-footer="$t('widget.Accelerating')"
        :left-header="quotas.DOMAIN + ''"
        :left-footer="$t('widget.AvailableDomainNames')"
        class="thesmall"
      >
        <CIcon name="cib-dashlane" height="52" class="my-4" />

        <!-- <CRow>
          <CCol md="12">
            <h2 ml="3">
              <CButton color="success" target="_blank" href="https://shoppy.gg/@rq0net/groups/MHnvGti">
                {{ $t('widget.AddDomainName') }}
              </CButton>
            </h2>
          </CCol>
          <CCol></CCol>
        </CRow> -->

      </CWidgetBrand>
    </CCol>

    <CCol md="3" sm="6">
      <CWidgetBrand
        color="gradient-success"
        :right-header="month_stat.nout + month_stat.nin | numeralFormat('0.00 b')"
        :right-footer="$t('widget.UsageFlowOfTheMonth')"
        :left-header="quotas.TRAFFIC"
        :left-footer="$t('widget.AvailableFlow')"
        class="thesmall"
      >
        <CIcon name="cib-buzzfeed" height="52" class="my-4 mx-3" />

        <CRow>
          <CCol md="12">
            <!-- <CButton color="warning" target="_blank" href="https://shoppy.gg/@rq0net/groups/lIInIpl">
              {{ $t('widget.PurchaseTraffic') }}
            </CButton> -->
            <CButton color="warning" :to="{name:'TrafficStatistics'}">
              <CIcon name="cil-chart-line" />
              {{ $t("traffic.TrafficReport") }}
            </CButton>
          </CCol>
          <CCol></CCol>
        </CRow>
      </CWidgetBrand>
    </CCol>

    <!-- <CCol md="3" sm="6">
      <CWidgetBrand
        color="gradient-success"
        :right-header="total_stat.nout + total_stat.nin | numeralFormat('0.0 b')"
        right-footer="已使用流量"
        :left-header="quotas.TRAFFIC"
        left-footer="總可用流量"
        class="thesmall"
      >
        <CIcon name="cib-buzzfeed" height="52" class="my-4 mx-3" />

        <CRow>
          <CCol md="12">
            <CButton color="warning" target="_blank" href="https://shoppy.gg/@rq0net/groups/lIInIpl">
              購買流量
            </CButton>
          </CCol>
          <CCol></CCol>
        </CRow>
      </CWidgetBrand>
    </CCol> -->

    <CCol md="2" sm="6">
      <!-- 域名流量趨勢。 找到流量前5的域名，繪圖 -->
      <CWidgetBrand
        color="linkedin"
        :right-header="today_stat.nout| numeralFormat('0.00 b')"
        :right-footer="$t('widget.OutputFlow')"
        :left-header="today_stat.nin| numeralFormat('0.00 b')"
        :left-footer="$t('widget.RequestTraffic')"
        class="thesmall"
      >
        <CIcon name="cib-buzzfeed" height="52" class="my-4 mx-3" />
        <CRow>
          <CCol md="12">
            <h2 ml="2">{{ today_stat.nout + today_stat.nin | numeralFormat('0.00 b') }}</h2>
          </CCol>
          <CCol>
            <CBadge>{{ $t('widget.TotalTrafficToday') }}</CBadge>
          </CCol>
        </CRow>
      </CWidgetBrand>
    </CCol>

    <CCol md="2" sm="6">
      <CWidgetBrand
        color="gradient-warning"
        :right-header="today_stat.pv | numeralFormat('0 a')"
        :right-footer="$t('widget.NumberOfRequests')"
        :left-header="today_stat.hit_pv | numeralFormat('0 a')"
        :left-footer="$t('widget.CacheHits')"
        class="thesmall"
      >
        <CIcon name="cib-ethereum" height="52" class="my-4" />
        <CRow>
          <CCol md="12">
            <h2 ml="2">{{1.0 * today_stat.hit_pv / today_stat.pv | numeralFormat('0.0 %') }}</h2>
          </CCol>
          <CCol>
            <CBadge>{{ $t('widget.CacheHitRate') }}</CBadge>
          </CCol>
        </CRow>
      </CWidgetBrand>
    </CCol>

    <!-- <CCol md="2" sm="6">
      <CWidgetBrand
        right-header="CC"
        right-footer="防禦中"
        left-header="DDOS"
        left-footer="防護中"
        color="gradient-success"
      >
        <CIcon name="cib-esea" height="52" class="my-4" />
        <CRow>
          <CCol md="12">
            <h2 ml="2">健康</h2>
          </CCol>
          <CCol>
            <CBadge>運行狀況</CBadge>
          </CCol>
        </CRow>
      </CWidgetBrand>
    </CCol> -->
    <PaymentCheckout :order="order" @hide="paymentShow=false" :paymentShow.sync="paymentShow" id="paymentcheckout"/>

    <!-- <CModal
      :title="$t('checkout.SelectPaymentMethod')"
      :show.sync="showModal"
      :closeOnBackdrop="false">
      <input type="radio"
             id="cryptocurrency"
             name="payment_type"
             value="cryptocurrency"
             v-model="selectedPaymentType">
      <label for="cryptocurrency">{{ $t('checkout.Cryptocurrency') }}</label>
      <ul id="coins" :class="{Disabled: selectedPaymentType !== 'cryptocurrency'}">
        <li v-for="coin in coinSupports"
            :key="'coin-' + coin.id"
            @click="clickCoins(coin.code, $event)">
          <div class="box"></div>
          <img :src="coin.icon"/>
          <p>{{ coin.name }}</p>
        </li>
      </ul>
      <input type="radio"
             id="accountbalance"
             name="payment_type"
             value="account_balance"
             v-model="selectedPaymentType">
      <label for="accountbalance">{{ $t('checkout.AccountBalance') }}</label>
      <p>{{ $t('checkout.YourBalance') }}: <b :class="{BalanceDanger: parseFloat($store.state.balance) < parseFloat($store.state.transaction.checkout.amount_currency1)}">{{ $store.state.balance }}</b></p>
      <template v-slot:footer>
        <button
            type="button"
            class="btn btn-secondary"
            @click="showModal=false">
          {{ $t('Cancel') }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="clickPayPlan()">
          {{ $t('Pay') }}
        </button>
      </template>
    </CModal> -->

    <CModal :title="memberInfo.level + ' - ' + memberInfo.cname"
            :show.sync="showModalSubscription"
            size="sm">
      <CRow class="text-center">
        <CCol>
          <div class="text-value-lg">
            $ {{ memberInfo.amount_price }}
          </div>
          <div class="text-uppercase text-muted small">
            {{ $t('widget.AmountPrice') }}
          </div>
        </CCol>

        <CCol>
          <div class="text-value-lg">
            {{ memberInfo.additional_domain_number }}
          </div>
          <div class="text-uppercase text-muted small">
            {{ $t('widget.AdditionalDomainNumber') }}
          </div>
        </CCol>
      </CRow>
      <template v-slot:footer-wrapper>
        <div></div>
      </template>
    </CModal>
  </CRow>
</template>

<script>
import axios from "@/plugins/axios.js";
// import { StripeCheckout } from "vue-stripe-checkout";
import { mapGetters } from "vuex";
import PaymentCheckout from '../payment/Checkout'
import { bus } from '@/main';
import { EventBus } from "@/plugins/event-bus.js";

const DOMAIN_STAT_CACHE_KEY = 'domain_stat';

export default {
  name: "WidgetsBrand",
  components: {
    PaymentCheckout
  },
  props: {
    noCharts: Boolean        
  },
  computed: {
    memberInfo: function() {
      let info = { level: this.$t('widget.FreeMembership'), expiredAt: "-", cname: "-", additional_domain_number: "-", additional_traffic: '-', amount_price: 0 };
      this.selectedSubscription = this.selectedSubscription ? this.selectedSubscription : this.subscriptions.length > 0 ? this.subscriptions[0] : null
      if (this.selectedSubscription) {
        if (this.selectedSubscription.plan) {
          info["level"] = this.selectedSubscription.plan.name;
          info["amount_price"] = this.selectedSubscription.amount_price;
          if (this.selectedSubscription.plan.price.quantity > 0) {
            info["expiredAt"] = this.selectedSubscription.next_bill_date;
          }
        } else {
          info["level"] = this.$t('widget.FreeMembership')
        }

        info["status"] = this.selectedSubscription.status;
        info["cname"] = this.selectedSubscription.cname;
        if ('DOMAIN' in this.selectedSubscription.add_meta) {
          info["additional_domain_number"] = this.selectedSubscription.add_meta.DOMAIN;
        }
        if ('TRAFFIC' in this.selectedSubscription.add_meta) {
          info["additional_traffic"] = this.selectedSubscription.add_meta.TRAFFIC;
        }
      }
      return info;
    },
    isNotifyTenDaysExpire() {
      const notifyExpireDays = 10;
      const expireDays = this.$moment(this.memberInfo.expiredAt).subtract(notifyExpireDays, 'days');
      const today = this.$moment();
      if (this.memberInfo.expiredAt === '-'){
        return false;
      } else if ( expireDays <= today) {
        return true;
      }
      return false;
    },
    clientReferenceId () {
      if ( this.authInfo && this.authInfo.sub ) {
        return this.authInfo.sub
      }
      else {
        return ''
      }
    },
    customerEmail() {
      if ( this.authInfo && this.authInfo.email ) {
        return this.authInfo.email
      }
      else {
        return ''
      }
    },

    subscriptionListFields() {
      return [
        {
          key: 'plan',
          label: this.$t('widget.PlanName')
        },
        {
          key: 'cname',
          label: this.$t('widget.CNAME')
        },
        {
          key: 'czone',
          label: this.$t('widget.CZONE')
        },
        {
          key: 'status',
          label: this.$t('widget.Status')
        },
        {
          key: 'additional_domain_number',
          label: this.$t('widget.AdditionalDomainNumber')
        },
        // {
          // key: 'additional_traffic',
          // label: this.$t('widget.AdditionalTraffic')
        // },
        {
          key: 'next_bill_date',
          label: this.$t('widget.ExpireAt')
        },
        {
          key: 'amount_price',
          label: this.$t('widget.AmountPrice')
        },
        {
          key: 'action',
          label: this.$t('widget.Action')
        }
      ]
    },
    ...mapGetters(["authInfo"]),
  },
  data() {
    return {
      selectedSubscription: null,
      selectedPaymentType: null,
      paymentShow: false,
      showModalSubscription: false,
      subscriptions: [],
      quotas: {},
      domain_stat: null,
      today_stat: {},
      month_stat: {},
      total_stat: {},
      stat: {},
      paymentTypes: "alipay",
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      checkBtnState: false,
      checkTrafficBtnState: false,
      page: 1,
      perpage: 2,
      total_rows: 0,
      total_pages: 0,
      coinSupports: [],
      order: {
        amountPrice: 0,
        type: ""
      },
    };
  },
  watch: {
    domain_stat() {
        this.domain_stat = this.domain_stat_value;
    }
  },
  created() {
    EventBus.$on('updateDomainStat', () => {
      this.fetchDomainStat();
    });
  },
  mounted() {
    if (window.localStorage) {      
      // pre-populate widget data with cache values - let's not wait for the ajax to display info
      
    }
    
    this.fetchQuota();
    //this.fetchTotalStat();
    this.fetchMonthStat();
    this.fetchTodayStat();
    this.fetchDomainStat();
    this.fetchCoinSupport();
  },
  methods: {
    pageChanged(page) {
      this.page = page
      this.fetchSubscription()
    },
    checkoutDomain() {
      this.$refs.checkoutRefDomain.redirectToCheckout();
    },
    checkoutTraffic() {
      this.$refs.checkoutRefTraffic.redirectToCheckout();
    },
    selectSubscription( subscription ) {
      this.selectedSubscription = subscription;
      this.$emit("selectSubscription", subscription);
    },

    fetchQuota() {
      axios
        .get(`plan/quota/`, {
          params: {},
          urlParams: {}
        })
        .then(response => {
          this.quotas = response.data;

          this.fetchSubscription(); //如果同時請求subscription和quota，在向stripe發起請求時候會報錯並發錯誤。
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchSubscription() {
      axios
        .get(`plan/subscription/`, {
          params: {},
          urlParams: {}
        })
        .then(response => {
          this.subscriptions = response.data;
          if (this.selectedSubscription) {
            this.selectedSubscription = this.subscriptions.find(item => {
              return item.id === this.selectedSubscription.id
            })
          }
          else {
            this.selectedSubscription = this.subscriptions.length > 0 ? this.subscriptions[0] : null
          }
          this._computedWatchers["memberInfo"].run()
          this.$forceUpdate()
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchDomainStat() {      
      axios
        .get(`domain/domain_stat/`, {
          params: {},
          urlParams: {}
        })
        .then(response => {
          this.domain_stat = response.data.running;
          this.domain_stat_value = response.data.running;          
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchTodayStat() {
      axios
        .get(`domain/today_stat/`, {
          params: {},
          urlParams: {}
        })
        .then(response => {
          this.today_stat = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchMonthStat() {
      axios
        .get(`domain/month_stat/`, {
          params: {},
          urlParams: {}
        })
        .then(response => {
          this.month_stat = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchTotalStat() {
      axios
        .get(`domain/total_stat/`, {
          params: {},
          urlParams: {}
        })
        .then(response => {
          this.total_stat = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchCoinSupport() {
      axios.get('plan/coin-support/').then(response => {
        this.coinSupports = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    // renew(type) {
    //   //this.order.type = "renew"
    //   this.order.amountPrice = this.selectedSubscription.amount_price
    //   this.order.subscription_id = this.selectedSubscription.id
    //   this.paymentShow = true
    // },
    pay(payType) {
      this.order.type = payType
      this.order.amountPrice = this.selectedSubscription.amount_price
      this.order.subscription_id = this.selectedSubscription.id
      this.paymentShow = true
    },
    clickModify() {
      this.$router.push({name: 'MemberSubscriptionModify', params: {id: this.selectedSubscription.id}})
    },
    clickPayPlan() {
      if (this.selectedPaymentType) {
        if (this.selectedPaymentType === 'cryptocurrency') {
          if (!this.selectedCoin) {
            this.flash(this.$t('checkout.WarningCoin'), 'warning')
            return
          }
        }
        if (this.selectedPaymentType === 'account_balance') {
          if (parseFloat(this.$store.state.balance) < parseFloat(this.selectedSubscription.amount_price)) {
            this.flash(this.$t('checkout.WarningBalance'), 'warning')
            return
          }
          this.selectedCoin = 'USDT'
        }
      }
    },
    clickCoins(coin, $event) {
      if (this.selectedPaymentType === 'cryptocurrency') {
        const li = document.querySelectorAll('ul#coins li')
        li.forEach(item => {
          item.classList.remove('Active')
        })
        const $target = $event.target
        $target.parentNode.classList.toggle('Active')
        this.selectedCoin = coin
      }
    }
  }
};
</script>

<style>
.c-chart-brand {
  position: absolute;
  width: 100%;
  height: 100px;
}

.thesmall .card-body div.text-value-lg {
  font-size: 1.1rem;
  word-wrap: normal;
}
.thesmall .card-body div.text-value-lg + div {
  word-wrap: normal;
}

.widget-subscription {
  position: relative;
}

.widget-subscription #icon-info {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  cursor: pointer;
}

div.Expired {
  background-color: #e55353;
  color: white;
}
div.Expired .text-muted {
  color: white !important;
}
.WidgetCname {
  margin-bottom: 5px;
  font-size: 0.9em;
}
.BalanceDanger {
  color: #e55353;
}
</style>
